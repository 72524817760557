import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Posts from 'components/ServerlessPosts';

const ServerlessPage = () => {
  return (
    <Layout>
      <SEO title="Serverless" description="Semua tentang Serverless" />
      <Posts />
    </Layout>
  );
};

export default ServerlessPage;
